<template>
  <auth-layout>
    <h2 class="auth-title">{{$t('auth.2_factor_login_page.title')}}</h2>
    <p v-html="$t('auth.2_factor_login_page.explanation' + (qrCode2fa ? '_with_qr' : ''))"/>
    <img v-if="qrCode2fa" :src="`data:image/jpeg;base64,${qrCode2fa}`" alt="QR" class="qr-code">
    <el-form ref="form" :model="form" @submit.prevent.native="null">
      <base-input
        :label="$t('auth.2_factor_login_page.form.input_code.label')"
        ref="code"
        prop="code"
        v-model="form.code2fa"
        :success="success"
        :error="error"
        :validations="$v.form.code2fa"
        name="code"
        auto-complete="off"
        @keyupEnter="handleSubmit"
      />
      <el-form-item>
        <div class="button-group">
          <a href="#" @click.prevent="$router.back()" class="grayed-link">
            {{ $t('general.button_back.label')}}
          </a>
          <el-button type="primary" round class="btn-with-chevron submit-btn" @click="handleSubmit"
                     :loading="loading">
                <span class="label">{{$t('auth.2_factor_login_page.form.button_reset.label')}}
                  <i class="ri-arrow-right-s-line"></i>
                </span>
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </auth-layout>
</template>

<script>
import AuthLayout from '../layouts/AuthLayout'
import BaseInput from '../components/BaseInput'
import { required } from 'vuelidate/lib/validators'
import store from '@/store'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TwoFactorLogin',
  components: {
    BaseInput,
    AuthLayout
  },
  data () {
    return {
      form: {
        code2fa: ''
      },
      loading: false,
      error: '',
      success: '',
      submitDisabled: false
    }
  },
  computed: {
    ...mapGetters({
      loginForm: 'auth/loginForm',
      qrCode2fa: 'auth/qrCode2fa'
    })
  },
  validations: {
    form: {
      code2fa: {
        required
      }
    }
  },
  methods: {
    ...mapActions({
      login: 'auth/login'
    }),
    async handleSubmit () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return true
      }
      this.loading = true
      try {
        await this.login({
          ...this.loginForm,
          code2fa: this.form.code2fa
        })
      } catch (e) {
        this.error = this.$t('validations.auth_code')
      }
      this.loading = false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.code.$refs.input.focus()
    })
  },
  beforeRouteEnter (to, from, next) {
    const form = store.getters['auth/loginForm']
    if (!form.username || !form.passdigest) {
      return next({ name: 'Login' })
    }
    next()
  }
}
</script>

<style lang="scss">

</style>
